<template>
  <div class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4 flex-between">
        <div class="N-Page T-size-36">Realtime Aircraft</div>
        <div class="box-S1">
          <v-menu
            v-model="menuselectdate"
            :close-on-content-click="false"
            :nudge-right="0"
            :nudge-top="10"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                class="input-date"
                append-icon="mdi-calendar-range"
                v-model="dateSelect"
                label="Date"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateSelect"
              @input="menuselectdate = false"
              @change="RenderSkyTrac()"
            ></v-date-picker>
          </v-menu>
        </div>
      </div>
    </div>

    <!-- Add -->
    <div id="ownerContent" class="wrap-Main">
      <div class="box-S4 flex-between-center noPadding">
        <div class="box-S1">
          <div class="B-carddetail">
            <div class="panal-title">Aircraft Name</div>
            <span class="center">
              <v-row>
                <v-col md="12">
                  <div class="T-size-18 SemiBold" v-if="ac_name != ''">
                    {{ ac_name }}
                  </div>
                </v-col>
              </v-row>
            </span>
          </div>
        </div>

        <div class="box-S1">
          <div class="B-carddetail">
            <div class="panal-title">Aircraft Model</div>
            <span class="center">
              <v-row>
                <v-col md="12">
                  <div class="T-size-18 SemiBold" v-if="ac_model != ''">
                    {{ ac_model }}
                  </div>
                </v-col>
              </v-row>
            </span>
          </div>
        </div>

        <div class="box-S1">
          <div class="B-carddetail">
            <div class="panal-title">Total Seat</div>
            <span class="center">
              <v-row>
                <v-col md="12">
                  <div class="T-size-18 SemiBold" v-if="ac_total_seat != ''">
                    {{ ac_total_seat }}
                  </div>
                </v-col>
              </v-row>
            </span>
          </div>
        </div>

        <div class="box-S1">
          <div class="B-carddetail">
            <div class="panal-title">Limit Weight</div>
            <span class="center">
              <v-row>
                <v-col md="12">
                  <div class="T-size-18 SemiBold" v-if="ac_limit_weight != ''">
                    {{ ac_limit_weight }}
                  </div>
                </v-col>
              </v-row>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div id="realtimeMap" class="wrap-Main">
      <div class="box-S3">
        <l-map :zoom="zoom" :center="center" style="z-index: 0; height: 65vh">
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-marker
            v-for="(item, index) in markers"
            :key="index"
            :lat-lng="item.location"
          >
            <l-icon
              :icon-url="item.iconcheckpoint"
              :icon-size="[32, 32]"
              :icon-anchor="[16, 16]"
            ></l-icon>
            <l-popup ref="popContent">{{ item.name }}</l-popup>
          </l-marker>

          <v-rotated-marker
            class="marker"
            v-for="(item, index) in markersnow"
            :key="index"
            :lat-lng="item.location"
            :icon="icon"
            :rotationAngle="item.rotate"
          >
            <l-popup ref="popContent">{{ item.name }}</l-popup>
          </v-rotated-marker>
          <l-polyline :lat-lngs="latlngs" color="#0BCC01"></l-polyline>
          <l-polyline :lat-lngs="latlngs2" color="#0000FF"></l-polyline>
        </l-map>
      </div>
      <div class="box-S1">
        <v-row>
          <v-col md="12">
            <div class="B-carddetail">
              <div class="panal-title">Flight Information</div>
              <span v-if="itemFlightInfo.length > 0">
                <v-list-item class="T-size-16">
                  <v-list-item-content>Flight No</v-list-item-content>
                  <v-list-item-content class="center"
                    >Departure</v-list-item-content
                  >
                  <v-list-item-content class="center"
                    >Arrival</v-list-item-content
                  >
                </v-list-item>

                <v-list
                  class="T-size-14"
                  v-for="item in itemFlightInfo"
                  :key="item.f_id"
                >
                  <v-list-item
                    @click="
                      RenderFlightDetail(
                        item.f_id,
                        item.f_departure_actual_date,
                        item.f_arrival_actual_date
                      )
                    "
                    class="item-click"
                  >
                    <v-list-item-content class="center T-size-16"
                      >{{ item.f_flight_no }}<br />{{ item.ac_name }}
                      <br /><v-icon color="indigo"
                        >mdi mdi-arrow-up-bold</v-icon
                      >
                      {{ item.cpoOut }}
                      <v-icon color="indigo">mdi mdi-arrow-down-bold</v-icon>
                      {{ item.cpoIn }}
                    </v-list-item-content>

                    <v-list-item-content class="center T-size-16"
                      >{{ item.ap_depart_date | yyyyMMdd }}
                      <div>
                        <span v-if="item.ap_depart_date != null">{{
                          item.ap_depart_date | HHmm
                        }}</span
                        ><span v-else>-</span>
                        /
                        <span v-if="item.f_departure_actual_date != null">{{
                          item.f_departure_actual_date | HHmm
                        }}</span
                        ><span v-else>-</span>
                      </div>
                    </v-list-item-content>

                    <v-list-item-content class="center T-size-16"
                      >{{ item.ap_dest_date | yyyyMMdd }}
                      <div>
                        <span v-if="item.ap_dest_date != null">{{
                          item.ap_dest_date | HHmm
                        }}</span
                        ><span v-else>-</span>
                        /
                        <span v-if="item.f_arrival_actual_date != null">{{
                          item.f_arrival_actual_date | HHmm
                        }}</span>
                        <span v-else>-</span>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </span>
              <span v-else>
                <div class="center">No Flight</div>
              </span>
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="box-S3 center">
        <v-row>
          <v-col md="3" class="center T-size-16 SemiBold">
            Speed
            <div class="center T-size-18 SemiBold">
              {{ speed }}
            </div>
          </v-col>
          <v-col md="1" class="center T-size-24">|</v-col>
          <v-col md="3" class="center T-size-16 SemiBold">
            Location
            <div
              class="center T-size-18 SemiBold"
              v-if="locationLat != '-' && locationLong != '-'"
            >
              {{ locationLat }}, {{ locationLong }}
            </div>
            <div class="center T-size-18 SemiBold" v-else>-</div>

            <div class="center T-size-16 SemiBold"></div>
          </v-col>
          <v-col md="1" class="center T-size-24">|</v-col>
          <v-col md="3" class="center T-size-16 SemiBold">
            Altitude
            <div class="center T-size-18 SemiBold">{{ altitude }}</div>
          </v-col>
        </v-row>
      </div>
      <div class="box-S1">
        <v-row></v-row>
      </div>

      <div class="box-S4">
        <div class="B-carddetail">
          <div class="panal-title">Event Log</div>
          <span class="center">
            <v-row>
              <v-col md="12">
                <v-list-item class="T-size-16">
                  <v-list-item-content class="center"></v-list-item-content>
                  <v-list-item-content>Aircraft</v-list-item-content>
                  <v-list-item-content>Time</v-list-item-content>
                  <v-list-item-content>Latitude</v-list-item-content>
                  <v-list-item-content>Longitude</v-list-item-content>
                  <v-list-item-content>Position</v-list-item-content>
                  <v-list-item-content>Altitude</v-list-item-content>
                  <v-list-item-content>Track</v-list-item-content>
                  <!-- <v-list-item-content>Speed</v-list-item-content> -->
                  <v-list-item-content>Event</v-list-item-content>
                  <v-list-item-content>Point</v-list-item-content>
                </v-list-item>

                <v-list
                  class="T-size-14"
                  v-for="item in itemEventLog"
                  :key="item.Id"
                >
                  <div
                    class="T-size-16 SemiBold"
                    v-if="item.t_posntypedescription != 'EnRoute'"
                  >
                    <v-list-item>
                      <v-list-item-content>
                        <span
                          v-if="item.t_posntypedescription == 'TransceiverOn'"
                          class="center T-size-18"
                          ><v-icon class="icon-brown"
                            >mdi mdi-checkbox-blank</v-icon
                          ></span
                        >
                        <span
                          v-if="item.t_posntypedescription == 'TransceiverOff'"
                          class="center T-size-18"
                          ><v-icon class="icon-black"
                            >mdi mdi-checkbox-blank</v-icon
                          ></span
                        >
                        <span
                          v-if="item.t_posntypedescription == 'WheelsOn'"
                          class="center T-size-18"
                          ><v-icon class="icon-brown"
                            >mdi mdi-menu-down</v-icon
                          ></span
                        >
                        <span
                          v-if="item.t_posntypedescription == 'WheelsOff'"
                          class="center T-size-18"
                          ><v-icon class="icon-blue"
                            >mdi mdi-menu-up</v-icon
                          ></span
                        >
                      </v-list-item-content>

                      <v-list-item-content class="T-size-18">{{
                        item.t_name
                      }}</v-list-item-content>
                      <v-list-item-content class="T-size-18">{{
                        item.t_posntime | HHmm
                      }}</v-list-item-content>
                      <v-list-item-content class="T-size-18">{{
                        item.t_latitude_string
                      }}</v-list-item-content>
                      <v-list-item-content class="T-size-18">{{
                        item.t_longitude_string
                      }}</v-list-item-content>
                      <v-list-item-content class="T-size-18"
                        >{{ item.t_geodistance }} nm</v-list-item-content
                      >
                      <v-list-item-content class="T-size-18"
                        >{{ item.t_altitude }} ft</v-list-item-content
                      >
                      <v-list-item-content class="T-size-18"
                        >{{ item.t_track }} T</v-list-item-content
                      >
                      <!-- <v-list-item-content class="T-size-18">{{
                        item.t_speed
                      }} kts</v-list-item-content> -->
                      <v-list-item-content class="T-size-18">{{
                        item.t_posntypedescription
                      }}</v-list-item-content>
                      <v-list-item-content class="T-size-18">{{
                        item.t_georef_ident
                      }}</v-list-item-content>
                    </v-list-item>
                  </div>
                </v-list>
              </v-col>
            </v-row>
          </span>
        </div>
      </div>
    </div>
    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="Back()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { latLng, icon } from "leaflet";
import {
  LMap,
  LTileLayer,
  LMarker,
  LIconDefault,
  LPolyline,
  LIcon,
  LPopup,
} from "vue2-leaflet";
import {
  startOfDay,
  endOfDay,
  format,
  addHours,
  addMinutes,
  differenceInMinutes,
} from "date-fns";
//Add
import { formatDistance } from "date-fns/esm";
import { th } from "date-fns/esm/locale";

import feathersClientUOA from "../plugins/feathers-client-uoa";
import feathersClientSkytrack from "../plugins/feathers-client-skytrack";
import Vue2LeafletRotatedMarker from "vue2-leaflet-rotatedmarker";
import fillerdate from "../utils/filterdate";
import { selectVirtualRow } from "@syncfusion/ej2-vue-grids";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPolyline,
    LIcon,
    LPopup,
    "v-rotated-marker": Vue2LeafletRotatedMarker,
  },
  data() {
    return {
      zoom: 8,
      center: L.latLng(7.1874908, 100.6022255),
      url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      markers: [],
      icon: icon({
        iconUrl: "https://api.prodigyfly.com/uploads/helicopter_new.png",
        iconSize: [60, 60],
        iconAnchor: [30, 30],
      }),
      latlngs: [],
      latlngs2: [],
      dataselect: [],
      selectolicenseplate: "นข4557 สงขลา",
      markersnow: [],
      markpoint: "https://api.prodigyfly.com/uploads/helicopter_new.png",
      tempdatalat: 0,
      tempdatalng: 0,
      itemsflight: null,

      //Add
      flightInformation: [],
      itemEventLog: [],

      //Add
      userdata: null,
      menuselectdate: false,
      dateSelect: new Date(),
      itemFlightInfo: [],
      actualTimeStart: "",
      actualTimeStop: "",
      ac_name: "-",
      ac_model: "-",
      ac_total_seat: "-",
      ac_limit_weight: "-",
      speed: "-",
      locationLat: "-",
      altitude: "-",
      totalDistance: "-",
    };
  },
  async mounted() {
    this.userdata = this.$CurrentUser.GetCurrentUser();
    this.dateSelect = format(this.dateSelect, "yyyy-MM-dd");
    await this.RenderSkyTrac();
  },
  methods: {
    Back() {
      this.$router.go(-1);
    },
    async renderline(unitname, sDate, eDate, flightID) {
      if (unitname == null) {
        unitname = "";
      }
      try {
        const q = {
          f_id: flightID,
          $sort: {
            t_posntime: 1,
          },
        };

        var res = await feathersClientSkytrack
          .service("flighttracking")
          .find({ query: q });

        if (res.total > 0) {
          var last = res.total - 1;
          var tackingDetail = res.data[last];

          //alert("tracking : " +  tackingDetail.t_latitude);
          //Add Render speed location altitude totalDistance
          // alert(res.total + " tracking : " +  JSON.stringify(tackingDetail));
          this.speed = tackingDetail.t_speed + " km/h";
          this.locationLat = tackingDetail.t_latitude;
          this.locationLong = tackingDetail.t_longitude;
          this.altitude = tackingDetail.t_altitude + " ft.";

          this.totalDistance = "";

          this.latlngs = [];
          this.latlngs2 = [];
          res.data.forEach((element) => {
            this.latlngs.push(
              eval("[" + element.t_latitude + "," + element.t_longitude + "]")
            );
          });

          this.markersnow = [];
          var c = {};
          c["location"] = eval(
            "L.latLng(" +
              res.data[0].t_latitude +
              "," +
              res.data[0].t_longitude +
              ")"
          );
          c["name"] = res.data[0].a_name;
          this.markersnow.push(c);
        } else {
          this.latlngs = [];
          this.latlngs2 = [];
        }
      } catch (err) {
        //this.$toast.error("ไม่สามารถติดต่อ server ได้" + err);
        alert(err);
      }
    },
    async renderCheckpoint() {
      try {
        const q = {};

        var res = await feathersClientSkytrack
          .service("checkpoint")
          .find({ query: q });

        res[0].MapData.forEach((element) => {
          let markcheckpoint = null;
          if (element.type === "Waypoint") {
            markcheckpoint = "https://api.prodigyfly.com/uploads/waypoint.png";
          } else if (element.type === "Fixed Installation") {
            markcheckpoint = "https://api.prodigyfly.com/uploads/oil-rig.png";
          } else if (element.type === "Airport") {
            markcheckpoint = "https://api.prodigyfly.com/uploads/airport.svg";
          } else if (element.type === "Vessel") {
            markcheckpoint = "https://api.prodigyfly.com/uploads/vessel.png";
          } else if (element.type === "Semi-Submersible") {
            markcheckpoint = "https://api.prodigyfly.com/uploads/oil-rig.png";
          } else if (element.type === "Jackup") {
            markcheckpoint = "https://api.prodigyfly.com/uploads/oil-rig.png";
          }

          var c = {};
          c["location"] = eval(
            "L.latLng(" +
              element.position.lat +
              "," +
              element.position.lng +
              ")"
          );
          c["iconcheckpoint"] = markcheckpoint;
          c["name"] = element.name;

          this.markers.push(c);
        });
      } catch (err) {
        this.$toast.error("ไม่สามารถติดต่อ server ได้" + err);
      }
    },

    async CheckJobActive() {},

    async RenderSkyTrac() {
      //Step clear data
      this.itemFlightInfo = [];
      this.itemEventLog = [];
      this.ac_name = "-";
      this.ac_model = "-";
      this.ac_total_seat = "-";
      this.ac_limit_weight = "-";
      this.speed = "-";
      this.locationLat = "-";
      this.altitude = "-";
      this.totalDistance = "-";

      //step 1 quary transport by o_id
      var quaryFlight = {
        $sort: {
          //มากไปน้อย
          ap_depart_date: -1,
        },
      };
      if (this.userdata.r_id == 2) {
        quaryFlight.o_id = this.userdata.o_id;
      }

      quaryFlight.ap_depart_date = {
        $gt: format(
          startOfDay(new Date(this.dateSelect)),
          "yyyy-MM-dd HH:mm:ss"
        ),
        $lt: format(endOfDay(new Date(this.dateSelect)), "yyyy-MM-dd HH:mm:ss"),
      };

      var res = await feathersClientUOA
        .service("viewflight")
        .find({ query: quaryFlight });
      var count = res.total;

      if (count > 0) {
        res.data.forEach(async (element) => {
          var actualDeparture = "";
          var actualArrival = "";
          var sDate = addMinutes(
            new Date(
              element.ap_depart_date.substring(0, 10) +
                " " +
                element.ap_depart_date.substring(11, 19)
            ),
            -30
          );
          var eDate = addHours(
            new Date(
              element.ap_dest_date.substring(0, 10) +
                " " +
                element.ap_dest_date.substring(11, 19)
            ),
            2
          );
          //alert("f_departure_actual_date : " + element.f_departure_actual_date)
          if (element.f_departure_actual_date == null) {
            var res = await this.CheckDepartureActualDate(
              element.ac_name,
              sDate,
              eDate
            );
            if (res.length > 0) {
              actualDeparture = res[0].t_posntime;
              element.f_departure_actual_date = actualDeparture;
              //Update
              var editData = { f_departure_actual_date: actualDeparture };
              await this.UpdateDepartureActualDate(element.f_id, editData);
            }
          }
          //alert("f_arrival_actual_date : " + element.f_arrival_actual_date)
          if (element.f_arrival_actual_date == null) {
            var res = await this.CheckArrivalActualDate(
              element.ac_name,
              sDate,
              eDate
            );
            if (res.length > 0) {
              actualArrival = res[0].t_posntime;
              element.f_arrival_actual_date = actualArrival;
              //Update
              var editData = { f_arrival_actual_date: actualArrival };
              await this.UpdateArrivalActualDate(element.f_id, editData);
            }
          }
          console.log(
            element.ac_name +
              " Departure : " +
              actualDeparture +
              " Arrival : " +
              actualArrival
          );
          //alert(element.ac_name + " Departure : " + actualDeparture + " Arrival : " +actualArrival)

          this.itemFlightInfo.push(element);
        });

        //alert("Data Complete : " + JSON.stringify(this.itemFlightInfo))
      }
    },
    async UpdateDepartureActualDate(flightId, actualDeparture) {
      var res = await feathersClientUOA
        .service("flight")
        .patch(flightId, actualDeparture);
    },
    async UpdateArrivalActualDate(flightId, actualArrival) {
      var res = await feathersClientUOA
        .service("flight")
        .patch(flightId, actualArrival);
    },

    async CheckDepartureActualDate(unitname, sDate, eDate) {
      var x = format(sDate, "yyyy-MM-dd HH:mm:ss");
      var y = format(eDate, "yyyy-MM-dd HH:mm:ss");
      const quaryEventLog = {
        name: unitname,
        start: x,
        end: y,
      };
      var res = await feathersClientSkytrack
        .service("get-departure-actual-date")
        .find({ query: quaryEventLog });
      return res;
    },
    async CheckArrivalActualDate(unitname, sDate, eDate) {
      var x = format(sDate, "yyyy-MM-dd HH:mm:ss");
      var y = format(eDate, "yyyy-MM-dd HH:mm:ss");
      const quaryEventLog = {
        name: unitname,
        start: x,
        end: y,
      };
      var res = await feathersClientSkytrack
        .service("get-arrival-actual-date")
        .find({ query: quaryEventLog });
      return res;
    },

    async RenderFlightDetail(flightId, departureActualDate, arrivalActualDate) {
      //step 1 quary transport by o_id
      var quaryFlight = {};
      var res = await feathersClientUOA.service("viewflight").get(flightId);

      var flightDetail = res;

      if (flightDetail.departairport == "VTSH") {
        this.center = L.latLng(7.1874908, 100.6022255);
      } else {
        this.center = L.latLng(12.677348781116454, 100.99881289556635);
      }

      //Flight Detail ข้างบน
      this.ac_name = flightDetail.ac_name;
      this.ac_model = flightDetail.ac_model;
      this.ac_total_seat = flightDetail.ac_total_seat;
      if (flightDetail.ac_limit_weight != null) {
        this.ac_limit_weight = flightDetail.ac_limit_weight + " kg";
      }

      //await this.renderline();
      //Edit
      //RealTime

      await feathersClientSkytrack
        .service("trackingevent")
        .on("created", (data) => {
          if (this.selectolicenseplate == data.t_name) {
            let rotate =
              Math.atan2(
                data.t_latitude - this.tempdatalat,
                data.t_longitude - this.tempdatalng
              ) *
              (270 / Math.PI) *
              -1;
            if (rotate < 0.0) rotate += 360.0;
            let radian = rotate.toFixed(5);
            this.markersnow = [];
            var c = {};
            c["location"] = eval(
              "L.latLng(" + data.t_latitude + "," + data.t_longitude + ")"
            );
            c["rotate"] = radian;
            c["name"] = data.t_name;
            this.markersnow.push(c);
            this.latlngs2.push(
              eval("[" + data.t_latitude + "," + data.t_longitude + "]")
            );
            this.tempdatalat = data.t_latitude;
            this.tempdatalng = data.t_longitude;
          }

          try {
            const q = {};
            q.$eager = "[aircrafts]";
            var res = feathersClientUOA.service("flight").find({ query: q });
          } catch (error) {
            console.log(error);
          }
        });

      this.renderCheckpoint();

      var sDate = "";
      var eDate = "";

      if (departureActualDate != null) {
        sDate = new Date(
          departureActualDate.substring(0, 10) +
            " " +
            departureActualDate.substring(11, 19)
        );
      } else {
        sDate = addMinutes(
          new Date(
            flightDetail.ap_depart_date.substring(0, 10) +
              " " +
              flightDetail.ap_depart_date.substring(11, 19)
          ),
          -30
        );
      }

      if (arrivalActualDate != null) {
        eDate = new Date(
          arrivalActualDate.substring(0, 10) +
            " " +
            arrivalActualDate.substring(11, 19)
        );
      } else {
        eDate = addHours(
          new Date(
            flightDetail.ap_dest_date.substring(0, 10) +
              " " +
              flightDetail.ap_dest_date.substring(11, 19)
          ),
          2
        );
      }

      await this.renderline(flightDetail.ac_name, sDate, eDate, flightId);
      //Add
      await this.renderEventLog(flightDetail.ac_name, sDate, eDate);
    },

    async renderEventLog(unitname, sDate, eDate) {
      var x = format(sDate, "yyyy-MM-dd HH:mm:ss");
      var y = format(eDate, "yyyy-MM-dd HH:mm:ss");

      const quaryEventLog = {
        name: unitname,
        start: x,
        end: y,
      };

      var res = await feathersClientSkytrack
        .service("geteventlog")
        .find({ query: quaryEventLog });
      this.itemEventLog = res;
      //alert(JSON.stringify(res));
    },

    // async renderSelect() {
    //   try {
    //     const q = {};
    //     var res = await feathersClientUOA
    //       .service("aircrafts")
    //       .find({ query: q });
    //     res.data.forEach((element) => {
    //       this.dataselect.push(element.ac_name);
    //     });
    //   } catch (err) {
    //     this.$toast.error("ไม่สามารถติดต่อ server ได้" + err);
    //   }
    // },
  },
};
</script>
<style>
.item-click {
  margin-top: 5px;
}

.icon-brown.mdi:before,
.mdi-set {
  color: #ba4a00 !important;
}
.icon-black.mdi:before,
.mdi-set {
  color: #1b2631 !important;
}
.icon-blue.mdi:before,
.mdi-set {
  color: blue !important;
}
</style>